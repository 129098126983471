<template>
	<div class="wrap" style="margin-top: 50px;">
		<div>
			<s-button type="primary" @btn-click="clickHandle(1)" btnText="收货地址"></s-button>
		</div>
		<div style="margin-top: 10px;">
			<s-button type="primary" @btn-click="clickHandle(2)" btnText="填写订单"></s-button>
		</div>
		<div style="margin-top: 10px;">
			<s-button type="primary" @btn-click="clickHandle(3)" btnText="处方单详情"></s-button>
		</div>
		<div style="margin-top: 10px;">
			<s-button type="primary" @btn-click="clickHandle(4)" btnText="登录页面"></s-button>
		</div>
		<div style="margin-top: 10px;">
			<s-button type="primary" @btn-click="clickHandle(5)" btnText="就诊人管理"></s-button>
		</div>
		<div style="margin-top: 10px;">
			<s-button type="primary" @btn-click="clickHandle(6)" btnText="评价"></s-button>
		</div>
		<div style="margin-top: 10px;">
			<s-button type="primary" @btn-click="clickHandle(7)" btnText="订单待支付"></s-button>
		</div>
	</div>
</template>
<script>
import sButton from '@/components/Button';
export default {
	components: { sButton },
	methods: {
		clickHandle (id) {
			let url = '';
			if (id === 1) {
				url = '/delivery-addr';
			} else if (id === 2) {
				url = '/fillin-order';
			} else if (id === 3) {
				url = '/prescription-detail?id=1';
			} else if (id === 4) {
				url = '/login';
			} else if (id === 5) {
				url = '/patient-list';
			} else if (id === 6) {
				url = '/appraise';
			} else if (id === 7) {
				url = '/consultation-pay?patientId=5';
			}
			this.$root.go(url);
		}
	}
};
</script>