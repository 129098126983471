<template>
	<div class="ssl-btn" :class="{'plain': plain, 'filling': !plain, 'customClass': customClass}" @click="$emit('btn-click')">{{btnText}}</div>
</template>
<script>
export default {
	props: {
		btnText: {
			type: String,
			default: '确定'
		},
		plain: {
			type: Boolean,
			default: false
		},
		customClass: {
			type: String,
			default: ''
		}
	}
};
</script>
<style lang="less" scoped>
	.ssl-btn {
		width: 104px;
		height: 40px;
		line-height: 40px;
		border-radius: 21px;
		text-align: center;
		font-size: 16px;
	}
	.plain {
		border: 1px solid #FF6450;
		color: #FF6450;
	}
	.filling {
		background:  linear-gradient(136deg, #FF7F4E 0%, #FF6453 100%);
		color: white;
	}
</style>