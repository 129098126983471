var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "wrap", staticStyle: { "margin-top": "50px" } },
    [
      _c(
        "div",
        [
          _c("s-button", {
            attrs: { type: "primary", btnText: "收货地址" },
            on: {
              "btn-click": function ($event) {
                return _vm.clickHandle(1)
              },
            },
          }),
        ],
        1
      ),
      _c(
        "div",
        { staticStyle: { "margin-top": "10px" } },
        [
          _c("s-button", {
            attrs: { type: "primary", btnText: "填写订单" },
            on: {
              "btn-click": function ($event) {
                return _vm.clickHandle(2)
              },
            },
          }),
        ],
        1
      ),
      _c(
        "div",
        { staticStyle: { "margin-top": "10px" } },
        [
          _c("s-button", {
            attrs: { type: "primary", btnText: "处方单详情" },
            on: {
              "btn-click": function ($event) {
                return _vm.clickHandle(3)
              },
            },
          }),
        ],
        1
      ),
      _c(
        "div",
        { staticStyle: { "margin-top": "10px" } },
        [
          _c("s-button", {
            attrs: { type: "primary", btnText: "登录页面" },
            on: {
              "btn-click": function ($event) {
                return _vm.clickHandle(4)
              },
            },
          }),
        ],
        1
      ),
      _c(
        "div",
        { staticStyle: { "margin-top": "10px" } },
        [
          _c("s-button", {
            attrs: { type: "primary", btnText: "就诊人管理" },
            on: {
              "btn-click": function ($event) {
                return _vm.clickHandle(5)
              },
            },
          }),
        ],
        1
      ),
      _c(
        "div",
        { staticStyle: { "margin-top": "10px" } },
        [
          _c("s-button", {
            attrs: { type: "primary", btnText: "评价" },
            on: {
              "btn-click": function ($event) {
                return _vm.clickHandle(6)
              },
            },
          }),
        ],
        1
      ),
      _c(
        "div",
        { staticStyle: { "margin-top": "10px" } },
        [
          _c("s-button", {
            attrs: { type: "primary", btnText: "订单待支付" },
            on: {
              "btn-click": function ($event) {
                return _vm.clickHandle(7)
              },
            },
          }),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }